define(function(require) {
    const CC = require('CC');
    const BasicModel = require('models/app/BasicModel');
    const _ = require('underscore');
    const Backbone = require('backbone');
    const { authorize } = require('utils/authorization');

    const ClientActivityModel = BasicModel.extend({
        defaults: function() {
            return {
                tagUniques: 0,
                audienceCount: 0,
                totalBehaviorCount: 0,
                totalCampaignCount: 0,
                activationChannelCount: 0,
                ingestorRecordCount: 0,
                apiRequestCount: 0,
                bcpCount: 0,
                ltJsCount: 0,
                syncJsCount: 0,
                otherCount: 0,
                totalUniques: 0
            };
        },

        urlRoot: `${CC.apiUrl}/reports/client/{clientId}/activity`,

        parse: function(response) {
            const self = this;
            // Combine the three different types of transactions
            if (self.has('totalTransactions')) {
                self.get('totalTransactions').reset(null);
                if (self.get('totalTransactions').fullCollection) {
                    self.get('totalTransactions').fullCollection.reset(null);
                }
            } else {
                self.set('totalTransactions', new Backbone.Collection());
            }

            const bcpCounts = _.map(response.bcpCounts, function(item) {
                return {
                    bcpCount: item.count,
                    dataDate: item.dataDate
                };
            });
            const ingestorRecords = _.map(response.ingestorRecords, function(item) {
                return {
                    ingestorRecordCount: item.count,
                    dataDate: item.dataDate
                };
            });
            const extractionApiRequests = _.map(response.extractionApiRequests, function(item) {
                return {
                    extractionApiRequestCount: item.count,
                    dataDate: item.dataDate
                };
            });
            const ltJsTransactions = _.map(response.ltJsTransactions, function(item) {
                return {
                    ltJsCount: item.count,
                    dataDate: item.dataDate
                };
            });
            const syncJsTransactions = _.map(response.syncJsTransactions, function(item) {
                return {
                    syncJsCount: item.count,
                    dataDate: item.dataDate
                };
            });
            const otherTransactions = _.map(response.otherTransactions, function(item) {
                return {
                    otherCount: item.count,
                    dataDate: item.dataDate
                };
            });

            const dates = _.uniq(
                _.pluck(bcpCounts, 'dataDate').concat(
                    _.pluck(ingestorRecords, 'dataDate'),
                    _.pluck(extractionApiRequests, 'dataDate'),
                    _.pluck(ltJsTransactions, 'dataDate'),
                    _.pluck(syncJsTransactions, 'dataDate'),
                    _.pluck(otherTransactions, 'dataDate')
                )
            );
            _.each(dates, function(date) {
                const combo = _.extend(
                    {
                        total: 0,
                        bcpCount: 0,
                        ingestorRecordCount: 0,
                        extractionApiRequestCount: 0,
                        ltJsCount: 0,
                        syncJsCount: 0,
                        otherCount: 0,
                        dataDate: date
                    },
                    _.findWhere(bcpCounts, { dataDate: date }),
                    _.findWhere(ingestorRecords, { dataDate: date }),
                    _.findWhere(extractionApiRequests, { dataDate: date }),
                    _.findWhere(ltJsTransactions, { dataDate: date }),
                    _.findWhere(syncJsTransactions, { dataDate: date }),
                    _.findWhere(otherTransactions, { dataDate: date })
                );
                combo.total = authorize('TransactionSourceBreakout') ? combo.ltJsCount + combo.syncJsCount + combo.otherCount + combo.ingestorRecordCount
                : combo.bcpCount + combo.ingestorRecordCount + combo.extractionApiRequestCount;
                self.get('totalTransactions').add(combo);
            });

            return response;
        }
    });

    return ClientActivityModel;
});
