import CC from 'CC';
const { authorize, authorizeForPanoramaIdCountStats } = require('utils/authorization');

/**
 * Returns an object containing authorization flags for the given audience.
 * @param {Audience?} audience
 * @return {Object}
 */
export const getAudienceAuthorizations = (audience = undefined) => {
    const canView = authorize('Audiences');
    const canManage =
        canView &&
        CC.utils.getCurrentClientInfo().get('enableAudienceOperations') &&
        CC.authorizeByPermission('Create/Save/Export Audiences/Enable APR');
    const canViewStats = canView && CC.authorizeByPermission('ViewAudienceStats');
    const canViewAnalytics =
        canViewStats && CC.authorizeByPermission('ViewAudienceAnalytics') && authorize('AudienceAnalyticsAccess');
    const canCreateEstimate = canViewStats && authorize('AudienceAnalyticsAccess', 'Create/Save Reach Estimates');
    const canManageDrafts = canView && CC.authorizeByPermission('manageAudienceDrafts') && canCreateEstimate;
    const canManageLookalikes = canManage && authorize('LookalikeAudiences', 'CreateLookalikeAudiences');
    const canViewProfileAudiences = CC.authorizeByPermission('ViewProfileAudiences');
    const canManageProfileAudiences = CC.authorizeByPermission('ManageProfileAudiences');

    const authorization = {
        canView: canView,
        canCreateEstimate: canCreateEstimate,
        canManage: canManage,
        canManageDrafts: canManageDrafts,
        canManageLookalikes: canManageLookalikes,
        canViewStats: canViewStats,
        canViewAnalytics: canViewAnalytics,
        canViewProfileAudiences: canViewProfileAudiences || canManageProfileAudiences,
        canManageProfileAudiences: canViewProfileAudiences && canManageProfileAudiences,
        hasAudienceList: canView,
        hasAnalyticsList: canViewAnalytics,
        hasBulkExport: canManage && authorize('AudienceExport'),
        hasLab: canManage || canManageDrafts || canCreateEstimate,
        lookalikeAdvancedTuning: authorize('LookalikeAdvancedTuning')
    };

    if (audience) {
        authorization.createEstimate = canCreateEstimate && !audience.isPrecisionModel();

        authorization.createLookalikeAudience =
            canManageLookalikes &&
            !audience.isLookalikeModel() &&
            !audience.isAllMembers() &&
            !audience.isPrecisionModel() &&
            audience.get('generate_apr');

        authorization.createModel = false;

        authorization.deleteAudience = canManage || (audience.isDraft() && canManageDrafts);

        authorization.editAudience =
            (canManage || (audience.isDraft() && canManageDrafts)) &&
            !audience.isPrecisionModel() &&
            !audience.isAllMembers() &&
            (!audience.isLookalikeModel() || (audience.isLookalikeModel() && authorize('LookalikeAudiencesLab'))); // Client must have explicit edit access for lookalike audience

        authorization.manageAudiences = canManage;

        authorization.manageDrafts = canManageDrafts;

        authorization.showPanoIdCountStats = authorizeForPanoramaIdCountStats();

        authorization.viewAudienceExportBeacons = canManage && authorize('AudienceExport') && !audience.isDraft();
        authorization.viewAudienceProfile =
            canView && canViewAnalytics && !audience.isLookalikeModel() && !audience.isSplit() && !audience.isDraft();
        authorization.viewASR = authorization.viewAudienceProfile;

        authorization.viewAudiences = canView;

        authorization.viewAudienceSplit =
            canManage && authorize('ABSplit') && !audience.isLookalikeModel() && !audience.isDraft();

        authorization.viewAudienceStats =
            canView &&
            CC.authorizeByPermission('ViewAudienceStats') &&
            !audience.isPrecisionModel() &&
            !audience.isLookalikeModel() &&
            !audience.isDraft();

        authorization.viewLookalikeAudienceStats =
            canView &&
            CC.authorizeByPermission('ViewAudienceStats') &&
            audience.isLookalikeModel() &&
            !audience.isDraft();

        authorization.viewBenchmarkOverlap =
            canView && authorize('ViewProfileAudiences') && authorization.viewAudienceProfile;

        authorization.viewSplits = authorization.viewAudienceSplit;
    }

    return authorization;
};
