import CC from '../../CC';
import { authorize } from '../../utils/authorization';

export const getOnboardingAuthorizations = (client, clientCollection) => {
    const authorization = {
        canViewFiles:
            authorize('Behaviors', 'View Behaviors') && CC.SessionData.clientFlags.get('onboardingEnabled') === true,
        canViewReports:
            CC.authorizeByPermission('View Behaviors') && CC.SessionData.clientFlags.get('onboardingEnabled') === true,
        canDeleteFiles:
            CC.authorizeByPermission('DeleteOnboardingFiles') &&
            CC.SessionData.clientFlags.get('onboardingEnabled') === true
    };
    if (client) {
        authorization.canUploadFiles =
            (CC.authorizeByPermission('UploadFiles') &&
                (client.isNetwork() &&
                    CC.SessionData.clientFlags.get('onboardingEnabled') === true &&
                    clientCollection?.length > 0)) ||
            (!client.isNetwork() && CC.SessionData.clientFlags.get('onboardingEnabled') === true) ||
            false;
    }
    return authorization;
};
export const getIngestorAuthorizations = () => {
    return {
        canViewFiles: CC.authorizeByPermission('View Client'),
        canUploadFiles: authorize('EZIngest', 'UploadFiles')
    };
};

export const getConnectionsAuthorizations = () => {
    return {
        canViewExports: authorize(['AudienceExport', 'Audiences'], 'Create/Save/Export Audiences/Enable APR'),
        canSeeClientSideActivity: CC.authorizeByPermission('View Client') && authorize('ClientSideActivity')
    };
};
